import { AppState } from '~/app-state'
import type { AvailableExperiments } from '~/models/experiments'
import type { IComputedExperiments, IFeatureFlag } from '~/types/interfaces/i-experiment'

export default () => {
  const { ssrContext } = useNuxtApp()

  const state = useState<IComputedExperiments>(AppState.Experiments, () => ssrContext?.event.context.experiments)

  const timesTracked = {} as Record<AvailableExperiments, number>

  const trackExperiment = async (experiment: IFeatureFlag) => {
    if (!experiment.trackable) return

    if (timesTracked[experiment.name] == null) timesTracked[experiment.name] = 0
    if (experiment.trackOnce && timesTracked[experiment.name] > 0) return

    await segmentTrack('Experiment Viewed', {
      experimentName: experiment.name,
      variantName: experiment.value
    })

    timesTracked[experiment.name]++
  }

  return {
    experiments: state.value,
    trackExperiment
  }
}
